import * as React from "react"

/*
 * You do not use pages, but everything is done through templates, as a result you do not have SSR.
 * Create a page, and then connect a template to it for SSR to work.
 * This is fake page!
 * Netlify bots can't work with JS pages, only with HTML.
 * This page uses SSR and is generated on the server, as a result, netlify bots know that the site has a form and include event handlers.
 * Forms:
 * - karrier
 * - felajanlott
 * - lomtalanitas
 * - contact
 * */
const Index = () => {
  return (
    <>
      <section className="section" style={{ display: "none" }}>
        <form
          name="karrier"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <div className="field">
            <label className="label" htmlFor={"firstName"}>
              firstName
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"firstName"}
                id={"firstName"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"email"}>
              email
            </label>
            <div className="control">
              <input
                className="input"
                type={"email"}
                name={"email"}
                id={"email"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"familyName"}>
              familyName
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"familyName"}
                id={"familyName"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"phone"}>
              phone
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"phone"}
                id={"phone"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"uploadUrls"}>
              uploadUrls
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"uploadUrls"}
                id={"uploadUrls"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"motivLetter"}>
              motivLetter
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"motivLetter"}
                id={"motivLetter"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"jobName"}>
              jobName
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"jobName"}
                id={"jobName"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"jobType"}>
              jobType
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"jobType"}
                id={"jobType"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"jobPlace"}>
              jobPlace
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"jobPlace"}
                id={"jobPlace"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <button className="button is-link">Send</button>
          </div>
        </form>
      </section>
      <section className="section" style={{ display: "none" }}>
        <form
          name="lomtalanitas"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <div className="field">
            <label className="label" htmlFor={"name"}>
              name
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"name"}
                id={"name"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"email"}>
              email
            </label>
            <div className="control">
              <input
                className="input"
                type={"email"}
                name={"email"}
                id={"email"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"phone"}>
              phone
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"phone"}
                id={"phone"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"postal"}>
              postal
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"postal"}
                id={"postal"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"address"}>
              address
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"address"}
                id={"address"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"items"}>
              items
            </label>
            <div className="items">
              <input
                className="input"
                type={"text"}
                name={"items"}
                id={"items"}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"comment"}>
              comment
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"comment"}
                id={"comment"}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"imagesUrls"}>
              imagesUrls
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"imagesUrls"}
                id={"imagesUrls"}
              />
            </div>
          </div>

          <div className="field">
            <button className="button is-link">Send</button>
          </div>
        </form>
      </section>
      <section className="section" style={{ display: "none" }}>
        <form
          name="felajanlott"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <div className="field">
            <label className="label" htmlFor={"name"}>
              name
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"name"}
                id={"name"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"email"}>
              email
            </label>
            <div className="control">
              <input
                className="input"
                type={"email"}
                name={"email"}
                id={"email"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"phone"}>
              phone
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"phone"}
                id={"phone"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"postal"}>
              postal
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"postal"}
                id={"postal"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"address"}>
              address
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"address"}
                id={"address"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"items"}>
              items
            </label>
            <div className="items">
              <input
                className="input"
                type={"text"}
                name={"items"}
                id={"items"}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"comment"}>
              comment
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"comment"}
                id={"comment"}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"imagesUrls"}>
              imagesUrls
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"imagesUrls"}
                id={"imagesUrls"}
              />
            </div>
          </div>

          <div className="field">
            <button className="button is-link">Send</button>
          </div>
        </form>
      </section>
      <section className="section" style={{ display: "none" }}>
        <form
          name="newsletter-signup"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <div className="field">
            <label className="label" htmlFor={"name"}>
              firstName
            </label>
            <div className="control">
              <input
                className="input"
                type={"text"}
                name={"name"}
                id={"name"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"email"}>
              email
            </label>
            <div className="control">
              <input
                className="input"
                type={"email"}
                name={"email"}
                id={"email"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"leadActions"}>
              leadActions
            </label>
            <div className="control">
              <input
                className="input"
                type={"checkbox"}
                name={"leadActions"}
                id={"leadActions"}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"purchaseActions"}>
              purchaseActions
            </label>
            <div className="control">
              <input
                className="input"
                type={"checkbox"}
                name={"purchaseActions"}
                id={"purchaseActions"}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor={"accept"}>
              accept privacy
            </label>
            <div className="control">
              <input
                className="accept"
                type={"checkbox"}
                name={"accept"}
                id={"accept"}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <button className="button is-link">Send</button>
          </div>
        </form>
      </section>
    </>
  )
}

export default Index
